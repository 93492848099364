import { RouterProvider } from "react-router-dom";
import { initFacebookPixel } from "./components/MetaPixel";
import TanstackQueryProvider from "./providers/tanstack-query-provider";
import { router } from "./routes";
// import LogRocket from "logrocket";
import Notiflix from "notiflix";
import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";
import { useEffect, useState } from "react";
import CacheBuster from "react-cache-buster";
import PulseLoader from "react-spinners/PulseLoader";
import { Toaster } from "sonner";
import { version } from "../package.json";
import GoogleTagManager from "./components/GoogleTagManager";
import { DSS_URL, GTM_IDS } from "./lib/config";
import isDev from "./lib/DevDetect";

// if (process.env.NODE_ENV === "production")
// 	LogRocket.init("mjknmk/daily-sale-shop");

const isProduction = !isDev();

if (isProduction) {
  posthog.init("phc_wrZr5P8OyRr0TSVpNcUdDSNDQKUQEEOrZI6i3Aqo0Kr", {
    api_host: "https://us.i.posthog.com",
    person_profiles: "always",
    capture_pageview: false,
  });
}

function App() {
  const [canAccess, setCanAccess] = useState<boolean>(true);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [currentVersion, setCurrentVersion] = useState(version);
  const [latestVersion, setLatestVersion] = useState(null);

  // const isProduction = process.env.NODE_ENV === "production";

  const domain = window.location.hostname as keyof typeof GTM_IDS;

  const checkForUpdate = async () => {
    const response = await fetch(`${DSS_URL}/settings`);
    const { version } = await response.json();
    return version;
  };

  useEffect(() => {
    initFacebookPixel();
    const timeout = setInterval(async () => {
      const newVersion = await checkForUpdate();
      setLatestVersion(newVersion);
      if (newVersion !== currentVersion) {
        Notiflix.Notify.info(
          "A new version is available. Please reload the page."
        );

        // Clear cache
        if ("caches" in window) {
          caches.keys().then((cacheNames) => {
            cacheNames.forEach((cacheName) => {
              caches.delete(cacheName);
            });
          });
        }

        // Save cart data before clearing
        const cartData = localStorage.getItem("dailysale-shop-cart");

        // Clear localStorage and sessionStorage
        localStorage.clear();
        sessionStorage.clear();

        // Restore cart data
        if (cartData) {
          localStorage.setItem("dailysale-shop-cart", cartData);
        }

        // Reload the page
        window.location.reload();
      }
    }, 30000); // Changed from 60000 to 30000 (30 seconds)

    return () => clearTimeout(timeout);
  }, [currentVersion]);

  // useEffect(() => {
  // 	checkLocation();
  // }, []);

  return canAccess ? (
    <CacheBuster
      currentVersion={version}
      isEnabled={isProduction} //If false, the library is disabled.
      isVerboseMode={false} //If true, the library writes verbose logs to console.
      loadingComponent={null} //If not pass, nothing appears at the time of new version check.
      metaFileDirectory={"."} //If public assets are hosted somewhere other than root on your server.
    >
      <TanstackQueryProvider>
        {isProduction && <GoogleTagManager gtmId={GTM_IDS[domain]} />}

        {/* <Plausible /> */}
        {/*;  here we add more providers, like global modals, auth etc */}
        <PostHogProvider client={isProduction ? posthog : null}>
          <RouterProvider router={router} />
        </PostHogProvider>
        <Toaster position="bottom-right" expand={true} richColors closeButton />
      </TanstackQueryProvider>
    </CacheBuster>
  ) : (
    <div className="flex w-full h-screen justify-center items-center">
      {isLoading ? <PulseLoader /> : "An Error Occurred."}
    </div>
  );
}

export default App;
