// 1. First, install the Facebook Pixel package
// npm install react-facebook-pixel --save

// 2. Create a FacebookPixel.js utility file
import ReactPixel from "react-facebook-pixel";

const PIXEL_ID = "477168205448449"; // Replace with your actual Pixel ID

const options = {
  autoConfig: true,
  debug: false, // Set to true during development
};

export const initFacebookPixel = () => {
  ReactPixel.init(PIXEL_ID, undefined, options);
  ReactPixel.pageView();
  console.log("Facebook Pixel initialized");
};

// Standard events
export const trackPageView = () => {
  ReactPixel.pageView();
};

export const trackEvent = (eventName, data = {}) => {
  ReactPixel.track(eventName, data);
};

// E-commerce specific events for Commerce Manager
export const trackViewContent = (product) => {
  ReactPixel.track("ViewContent", {
    content_name: product.title,
    content_category: product.category,
    content_ids: [`PLID${product.sku}`],
    content_type: "product",
    value: product.price,
    currency: "USD", // Change to your currency
  });
};

export const trackAddToCart = (product, quantity = 1) => {
  ReactPixel.track("AddToCart", {
    content_ids: [`PLID${product.sku}`],
    content_name: product.title,
    content_type: "product",
    value: product.price * quantity,
    currency: "USD",
    contents: [
      {
        id: `PLID${product.sku}`,
        quantity: quantity,
        item_price: product.price,
      },
    ],
    num_items: quantity,
  });
};

export const trackInitiateCheckout = (cartItems) => {
  const contentIds = cartItems.map((item) => `PLID${item.sku}`);
  const value = cartItems.reduce(
    (total, item) => total + item.price * item.quantity,
    0
  );
  const numItems = cartItems.reduce((total, item) => total + item.quantity, 0);

  ReactPixel.track("InitiateCheckout", {
    content_ids: contentIds,
    content_type: "product",
    value: value,
    currency: "USD",
    num_items: numItems,
    contents: cartItems.map((item) => ({
      id: `PLID${item.sku}`,
      quantity: item.quantity,
      item_price: item.price,
    })),
  });
};

export const trackPurchase = (order) => {
  const contentIds = order.items.map((item) => `PLID${item.sku}`);

  ReactPixel.track("Purchase", {
    content_ids: contentIds,
    content_type: "product",
    value: order.total,
    currency: "USD",
    num_items: order.items.length,
    contents: order.items.map((item) => ({
      id: `PLID${item.sku}`,
      quantity: item.quantity,
      item_price: item.price,
    })),
    transaction_id: order.id,
  });
};
