import StoreLayout from "@/components/layouts/store-layout";
import { PixelTracker } from "@/components/PixelTracker";
import React from "react";
import {
  createBrowserRouter,
  // Outlet,
  RouteObject,
} from "react-router-dom";
import AuthRoute from "./AuthRoute";

// Error Boundary Component
interface ErrorBoundaryProps {
  children: React.ReactNode;
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps> {
  state = { hasError: false };

  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {}

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className="flex h-screen w-full justify-center items-center">
          <h1>Something went wrong.</h1>
        </div>
      );
    }
    return this.props.children;
  }
}

export const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <>
        <PixelTracker />
        <StoreLayout />
      </>
    ),
    errorElement: (
      <ErrorBoundary>
        <div />
      </ErrorBoundary>
    ),
    children: [
      {
        index: true,
        element: <AuthRoute guestOnly lazy={() => import("../pages/home")} />,
      },
      {
        path: "featured/:featured_type",
        element: <AuthRoute guestOnly lazy={() => import("../pages/home")} />,
      },
      {
        path: "back-to-school",
        element: (
          <AuthRoute guestOnly lazy={() => import("../pages/back-to-school")} />
        ),
      },
      {
        path: "search/:search_query",
        element: <AuthRoute guestOnly lazy={() => import("../pages/home")} />,
      },
      {
        path: "categories/:department/:category",
        element: <AuthRoute guestOnly lazy={() => import("../pages/home")} />,
      },
      {
        path: "products/:sku",
        element: (
          <AuthRoute
            guestOnly
            lazy={() => import("../pages/product-details")}
          />
        ),
      },
      {
        path: "cart",
        element: (
          <AuthRoute guestOnly lazy={() => import("../pages/newcart")} />
        ),
      },
      {
        path: "wishlist",
        element: (
          <AuthRoute guestOnly lazy={() => import("../pages/wishlist")} />
        ),
      },
      {
        path: ":page/:filter",
        element: <AuthRoute guestOnly lazy={() => import("../pages/home")} />,
      },
      {
        path: "/about-us",
        element: (
          <AuthRoute
            guestOnly
            lazy={() => import("../pages/content/about-us")}
          />
        ),
      },
      {
        path: "/contact-us",
        element: (
          <AuthRoute
            guestOnly
            lazy={() => import("../pages/content/contact-us")}
          />
        ),
      },
      {
        path: "/privacy-policy",
        element: (
          <AuthRoute
            guestOnly
            lazy={() => import("../pages/content/privacy-policy")}
          />
        ),
      },
      {
        path: "/terms-and-conditions",
        element: (
          <AuthRoute
            guestOnly
            lazy={() => import("../pages/content/terms-and-conditions")}
          />
        ),
      },
      {
        path: "/shipping-and-delivery",
        element: (
          <AuthRoute
            guestOnly
            lazy={() => import("../pages/content/Shipping-and-delivery")}
          />
        ),
      },
      {
        path: "/payment-policy",
        element: (
          <AuthRoute
            guestOnly
            lazy={() => import("../pages/content/payment-policy")}
          />
        ),
      },
      {
        path: "/warranty-policy",
        element: (
          <AuthRoute
            guestOnly
            lazy={() => import("../pages/content/warranty-policy")}
          />
        ),
      },
      {
        path: "/refund-policy",
        element: (
          <AuthRoute
            guestOnly
            lazy={() => import("../pages/content/refund-policy")}
          />
        ),
      },
      {
        path: "/help",
        element: <AuthRoute guestOnly lazy={() => import("../pages/help")} />,
      },
      {
        path: "*",
        element: (
          <AuthRoute guestOnly lazy={() => import("../pages/not-found")} />
        ),
      },
    ],
  } as RouteObject,
]);
