import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { trackPageView } from "./MetaPixel";

export function PixelTracker() {
  const location = useLocation();

  useEffect(() => {
    trackPageView();
  }, [location]);

  return null;
}
