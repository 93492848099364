// export const DSS_URL = "https://dss-express.onrender.com";
// export const DSS_URL = "https://api.dssdeliver.com";
// export const DSS_URL = "http://localhost:3000";
export const DSS_URL = import.meta.env.VITE_DSS_URL;

export const GTM_IDS = {
	"dailysale.co.zw": "GTM-5SV37FKR",
	"dailysale.co.zm": "GTM-5QT7B5DW",
};

